// import knuthShuffleSeeded from 'knuth-shuffle-seeded';
const sub = 'abcdefghijklmnopqrstuvxyz'.split('')

const extractQuestion = function(testData, shuffle = null, user_id  = Math.random(), review = false){
    var questions = [];
    var qnf = 1
    try{
        testData.format.parts.forEach( (part, pi) => {
            let ptemp = [], pqc = 0 // part question count, required if part sq is on and section treat as question
            part.sections.forEach( (section, si) => {
                let stemp = section.questions.map(q => {return { ...q, pi, si, noo: q.noo || 4 }})
                if(!review) { if(shuffle=='section' || section.sq) stemp = stemp.shuffle(true, user_id+si) }// knuthShuffleSeeded(stemp, user_id+si)
                
                // if question number inside section is to be renamed as sub question
                if(section.tap) {
                    stemp = stemp.map((qq, index) => { return {...qq, qnf: String(qnf)+sub[index], so: section.so}})
                    qnf += 1
                    pqc += 1
                }
                else {
                    stemp = stemp.map((qq, index) => { return {...qq, qnf: qnf+index, so: section.so}})
                    qnf += section.noq
                    pqc += section.noq
                }
                part.pqc = pqc
                ptemp = ptemp.concat(stemp)
            })
            if(!review){
                if(shuffle=='part' || part.sq) {
                    qnf -= part.pqc
                    ptemp = ptemp.shuffle(true, user_id+pi).map((qq, index) => { return {...qq, qnf: qnf+index, so: part.so}})
                    qnf += part.noq
                } // knuthShuffleSeeded(ptemp, user_id+pi)
            }
            questions = questions.concat(ptemp)
        })
        if(!review){
            if(shuffle=='all' || testData.format.sq) questions = questions.shuffle(true, user_id) // knuthShuffleSeeded(questions, user_id)
        }
    } catch(e){
        console.log(e)
    }
    return questions
}


const appendUserData = function(questions, userData){
    return questions.map((question) => {
        var temp = userData[parseInt(question.qn)-1] || {}
        return {
            ...question, 
            ua: temp.ua, 
            ts: temp.ts, 
            ats: temp.ats, 
            mts: temp.mts, 
            ic: temp.ic, 
            pm: temp.pm, 
            nm: temp.nm, 
            iu: temp.iu, 
            vu: temp.vu, 
            qt: temp.qt, 
            r: temp.r, 
            s: temp.s, 
            ic: temp.ic
        } 
    })
}

const hasAnswer = function(question){
    let bool = false
    if(question.qt==4) bool = question.ua.length
    else if(question.qt==3 || question.qt==7) bool = question.ua!=null && parseInt(question.ua)>-1
    else if(question.qt==6) bool = question.ua!=null
    else if(question.qt==8) {
        bool = question.ua!=null || question.iu.length>0 || question.vu.length>0
    }
    return bool
}
export {extractQuestion, appendUserData, hasAnswer} 