<template>
<div>

  <div v-if="!error && testData">
    <v-layout  :class="$vuetify.breakpoint.mdAndUp?'row':'column'" class="mathcontent min80vh">
      <v-flex xs12 md8 >
        <h3 class="title mb-2">{{testData?testData.name:''}}</h3>
        <template v-for="(question,_qi) in questions">
            
          <div :key="_qi" v-if="qi==_qi">
            <h3 class="subheading mb-4">&nbsp;Question 
              <b>{{qi+1}}</b> of 
              <b>{{totalQuestions}}</b>  
              <template>
                <v-chip v-if="question.s>0" style="float:right;top:4px" color="green" text-color="white">
                  <v-avatar :class="`${(question.s>0 && question.s < question.pm)?'yellow darken-4':'green darken-4'}`">+{{question.s}}</v-avatar> <template v-if="testData.type!=='aio-pdf'">{{(question.s>0 && question.s < question.pm)?'Partial':''}} Correct</template>
                </v-chip>
                <v-chip v-else style="float:right;top:4px" :color="question.ats?'red':'secondary'" text-color="white">
                  <v-avatar class="darken-4" :class="question.ats?'red':'secondary'">{{Math.abs(question.s)>0?question.s:(question.ats?question.nm:0)}}</v-avatar>{{(Math.abs(question.s)>0 || question.ats)?'Wrong':'NA'}}
                </v-chip>
              </template> 
            </h3>

            <p>Type: <i>{{questionType[question.qt]}}</i> 
              <span style="float:right">Max Marks: {{question.pm}}</span>
            </p>
            
            <question-item 
              :key="question.id" 
              :_question="question" 
              :readonly='true'
              v-if="testData.type!=='aio-pdf'"> 
            </question-item>

             <question-item-pdf 
              v-else
              ref="activeQuestionItem"
              :key="question.id" 
              :_question="question" 
              :ronly="true"
              ></question-item-pdf>

              <v-card rounded style="border-radius: 10px" class="mt-3">
              <v-card-text>
                Correct Answer: {{ userData.test_data[question.qn-1]['ca'] | filterAnswer(question.type) }}
                <br>
                <v-divider></v-divider>
                Your Answer: {{ userData.test_data[question.qn-1]['ua'] | filterAnswer(question.type) }}
              </v-card-text>
            </v-card>

            
            <v-card rounded style="border-radius: 10px" class="mt-3">
              <v-card-text>
                Teacher's Remark :
                <br>
                <v-divider></v-divider>
                <br>
                <p v-html="question.r || 'None'"></p>
              </v-card-text>
            </v-card>

          <template v-if="$vuetify.breakpoint.smAndDown">
            <br>
            <v-divider></v-divider>
            <image-grid v-if="userData && userData.iu && userData.iu.length" :images="userData.iu" :hideActions="true" ></image-grid>

            <v-divider></v-divider>
            <template v-if="userData && userData.pu && userData.pu.length">
              <v-btn   v-for="(pu, ind) in userData.pu" :key="ind" target="_blank" @click="pdfClick(pu)" >PDF {{ind+1}}</v-btn>
            </template>
          </template>

          </div>
        </template>
        <div>
          <br><br>
          <br><br>
        </div>
      </v-flex>
      <!-- DESKTOP QUESTION NAVIGATOR -->
      <v-flex xs12 md4 offset-md1  v-if="$vuetify.breakpoint.mdAndUp" style="padding-bottom: 100px;">
        <v-card color="blue-grey darken-2 mb-1" class="white--text" hover style="border-radius: 10px">
          <v-card-text>
            <template v-if="testData.type!=='aio-pdf'">
              <question-nav-all ></question-nav-all>
              <br>
                <v-divider></v-divider>
              <br>
            </template>
              <h3 class="title mb-1">Test Remark :</h3> 
              <span v-html="userData && userData.remark && userData.remark.length?userData.remark:'None'"></span>
          </v-card-text>
        </v-card>
          <v-divider></v-divider>
          <image-grid v-if="userData && userData.iu && userData.iu.length" :images="userData.iu" :hideActions="true" ></image-grid>

          <v-divider></v-divider>
          <template v-if="userData && userData.pu && userData.pu.length">
            <v-btn v-for="(pu, ind) in userData.pu" :key="ind" target="_blank" 
            @click="pdfClick(pu)"
            :_href="pu | filterImage(userData.pu_image, testData.config.CDN+'test-files/')">PDF {{ind+1}}</v-btn>
          </template>

        <br>
      </v-flex>
    </v-layout>
 
    <!-- MOBILE QNAVIGATOR -->
    <v-dialog v-model="qNavDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card color="blue-grey darken-2 ">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="qNavDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-items>
          <div class="subheading pt-3">
            <small class="timer-icon"><v-icon>schedule</v-icon> &nbsp;</small>
            <span v-html="timeLeftHTML"></span>
          </div> 
        </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
           <question-nav-all ></question-nav-all>
            <span v-html="userData && userData.remark && userData.remark.length?userData.remark:'None'"></span>
        </v-card-text>
      </v-card>
    </v-dialog>


<v-bottom-sheet hide-overlay value="true" persistent>
  <v-card tile>
    <v-progress-linear indeterminate class="my-0"  height="3" v-if="syncing" ></v-progress-linear>
    <v-list>
      <v-list-tile class="text-xs-center" >
        <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-list-tile-action > <v-btn :disabled="prevDisabled" :color="fab==0?'blue':''" :outline="fab==0" @click="prev(true)">  Prev  </v-btn> </v-list-tile-action> &nbsp;
        <v-list-tile-action > <v-btn :disabled="nextDisabled" :color="fab==1?'blue':''" :outline="fab==1"  @click="next(true)"> Next </v-btn>  </v-list-tile-action>&nbsp;
        
        <v-list-tile-action v-if="questions.length && selectedQ">  
          <v-btn dark color="orange" :round="fab==2" :class="{'elevation-4':fab==2}" @click="showDialog('Solution')"> Solution </v-btn> 
        </v-list-tile-action>&nbsp;
        <v-list-tile-action> 
          <v-btn dark color="indigo" :fab="fab==3" :loading="fab==3" @click="showDialog('Answer')"> Answer </v-btn> 
        </v-list-tile-action>
         <v-spacer></v-spacer>
         <v-list-tile-action v-if="testData"><v-btn color="blue-grey darken-2" :outline="fab==4" dark :to="'/test/'+userData._id+'/result'"> Result </v-btn></v-list-tile-action>&nbsp;
        <!-- <v-list-tile-action v-if="testData"><v-btn color="grey darken-4" :outline="fab==5" dark :to="retestLink"> Re-Test </v-btn></v-list-tile-action> -->
       
         </template>
        <template v-else :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"> 
          <v-list-tile-action > 
            <v-btn :disabled="prevDisabled" :color="fab==0?'blue':''"  @click="prev(true)" icon>  
              <v-icon dark>keyboard_arrow_left</v-icon>
            </v-btn> 
          </v-list-tile-action> 
          <!-- <v-spacer></v-spacer> -->
          <v-list-tile-action> 
            <v-btn :disabled="nextDisabled" :color="fab==1?'blue':''"  @click="next(true)" icon> 
                <v-icon dark>keyboard_arrow_right</v-icon>
            </v-btn>  
          </v-list-tile-action>

          <!-- <v-spacer></v-spacer> -->

          <v-list-tile-action> 
            <v-btn  @click="qNavDialog=!qNavDialog" icon> 
              <v-icon dark>{{qNavDialog?'dialpad':'dialpad'}}</v-icon>
            </v-btn>  
          </v-list-tile-action>
          <!-- <v-spacer></v-spacer> -->
          <v-list-tile-action v-if="questions.length && selectedQ">  
              <v-btn dark flat  color="orange" :round="fab==2" :class="{'elevation-4':fab==2}" @click="showDialog('Solution')"> Solution </v-btn> 
          </v-list-tile-action>&nbsp;
          <v-list-tile-action> 
            <v-btn dark flat  color="indigo" :fab="fab==3" :loading="fab==3" @click="showDialog('Answer')"> Answer </v-btn> 
          </v-list-tile-action>
        </template>
      </v-list-tile>
    </v-list>
  </v-card>
  </v-bottom-sheet>
  

<!-- LOADING PROGRESS -->
  <v-dialog  v-model="overlayLoading" persistent width="300">
    <v-card color="info" dark>
      <v-card-text>
        {{overlayMessage}}
        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>

   <v-dialog  v-model="pdfImageDialog"  fullscreen >
    <v-card dark>
      <v-card-text>
        <image-grid :images="pdfImages" :hideActions="true" title="PDF"></image-grid>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="pdfImageDialog=false" color="info" style="position: fixed; bottom: 0; right: 50vw">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


  </div>
  <div v-else class="text-xs-center"> 
    <h3 class="display-2 pt-4">{{error}}</h3> 
  </div>
  <answer-solution :type="dialog.type" :model="dialog.model" :question="dialog.question" @close="dialog.model=false"></answer-solution>
  <report-question :open="report.open" :question="report.question" @reported="resetReport()"></report-question>
    
</div>
</template>
<script>
import TestToolbar from './Test/TestToolbar'
import TestQuestionItem from './../components/TestQuestionItem.vue'
import QuestionNavigatorAll from './../components/QuestionNavigatorAll.vue'
import {extractQuestion, appendUserData} from './../utils/questionExtractor'
import { mapGetters, mapActions  } from 'vuex'
import AnswerSolutionDialog from './../components/AnswerSolutionDialog.vue'
import ReportQuestion from './../components/ReportQuestion.vue'
import {renderMathJax} from './../utils.js'
import ImageGrid from './../components/ImageGrid'
import QuestionItemPdf from './Test/QuestionItemPdf';
import NavigateHelper from './Test/utils/NavigateHelper'

export default{
	data(){
	  return {
      totalQuestions: 0,
      testData: null,
      userData: null,
      instructions: null,
      loading: false,
      overlayLoading: false,
      overlayMessage: '',
      qNavDialog: false,
      showSectionInstruction: false,
      fab: -1,
      syncing: false,
      submitdialog: false,
      uploadDialog: false,
      error: null,
      resultLink: '',
      dialog: {
          model: false,
          question: null,
          type: ''
      },
      choices: ['A','B','C','D'],
      qNavDialog: false,
      report: {
        open: false,
        question: null
      },
      questionType: {
        1:'Subjective',
        3:'MCQ Single Correct',
        4:'MCQ Multi Correct',
        5:'Paragraph',
        6:'Numerical Type',
        8:'Subjective',
      },
      pdfImageDialog: false,
      pdfImages: []
    }
	},
	mounted(){
    this.resultLink = {path: '/test/'+(this.userData?this.userData._id:this.$route.params.id)+'/result'}
    this.$store.commit('set_in_test', false)
    this.extractData(this.mainData)
	},
	methods:{
    ...mapActions(['nextQuestion', 'prevQuestion']),
    extractData(data){
        if(data==null) return
      this.testData = data.testData
      this.userData = data.userData
      this.instructions = data.instructions
      this.totalQuestions = parseInt(data.userData.test_data[data.userData.test_data.length-1].qn)

      this.$store.commit('set_questions', appendUserData(extractQuestion(this.testData, null, this.user.id, true), data.userData.test_data))
      this.$store.commit('set_test_config', data.testData.config)
      if(this.$route.query.qi){
        this.$store.dispatch('selectQuestion', parseInt(this.$route.query.qi) || 0)

      }
    },
    removeLocalTestData(){
      if(sessionStorage) sessionStorage.removeItem(this.$route.params.id)
    },
    showDialog(type){
      this.dialog.type = type
      this.dialog.question = this.questions.filter(qq => qq.qn==this.selectedQ.qn)[0] || {}
      this.dialog.model = true

      this.$nextTick(() => {
      	renderMathJax(this, '.dialog-mathcontent')
      })
	},
   
    prev() {
      if(!this.prevDisabled)  this.prevQuestion()
      this.$nextTick(() => {
         NavigateHelper.updateRoute(this)
        this.revertFab()
      })
    },
    pdfClick(pu){
      var pdfImages = this.userData.pdf_images
      var puImages = this.userData.pu_image || [] // legacy
      if(pdfImages && pdfImages[pu]){
        this.pdfImageDialog = true
        this.pdfImages = pdfImages[pu]
      } else if(puImages && puImages.includes(pu+"-annotate.png")){
        window.open(this.testData.config.CDN+'test-files/'+pu+"-annotate.png", "", "width=300,height=500");
      } else {
        window.open(this.testData.config.CDN+'test-files/'+pu, "", "width=300,height=500");
      }
    },
    next() {
      this.fab = 1
      this.revertFab()
      if(!this.nextDisabled) {
        this.nextQuestion()
        NavigateHelper.updateRoute(this)  
      } else {
        this.$store.commit('open_snackbar', {text: 'You have reached last question of test.', color: 'info'})
      }
    },
    revertFab() {
      setTimeout(() => {
        this.fab = -1
        this.syncing = false
      },1000)
    },
    resetReport(){
        this.report = {
          open: false,
          question: null
        }
    },
   },
   beforeDestroy(){
    this.$store.commit('set_in_test',    false)
    this.$store.commit('toggle_toolbar', true)
   },
  computed: {
    ...mapGetters(['user', 'qi', 'questions']),
    mainData(){
      return this.$parent.testData?this.$parent.testData:null
    },
    selectedQ(){
      return this.userData && this.userData.test_data && this.userData.test_data[this.qi]?this.userData.test_data[this.qi]: null
    },
    prevDisabled(){
      return this.qi <= 0
    },
    nextDisabled(){
      return this.qi==this.totalQuestions-1
    },
    testURL() {
      return '/test/in/'+this.$route.params.id
    },
    disableClear(){
      if(this.selectedSubQ ) return this.selectedSubQ.ua==null
      return this.selectedQ.ua==null || (this.selectedQ.ua.constructor == Array && this.selectedQ.ua.length == 0)
    },
    hideBottomNav(){
      return this.submitdialog || this.overlayLoading
    },
    selectedSection(){
      return this.sections && this.sections[this.selected.section]
    },
    sectionInstructions(){
      return this.selectedSection?this.sections[this.selected.section].instruction:''
    },
    showWarningDialog(){
      return this.showWarning && this.warningDialogOffset<=0
    },
    timeLeftHTML(){
      return `Not required`
    },
    qn(){
      return this.qi+1
    }
  },
  components: {
    'question-item-pdf': QuestionItemPdf,
    'question-item': TestQuestionItem,
    'question-nav-all': QuestionNavigatorAll,
    'test-toolbar': TestToolbar,
    'report-question': ReportQuestion,
    'answer-solution': AnswerSolutionDialog,
    'image-grid': ImageGrid,
  },
  filters: {
    filterImage(pu, pu_images = [], CDN) {
      console.log(pu, CDN, pu_images)
      let temp = pu+'-annotate.png'
      return `${CDN}test-files/${pu_images.indexOf(temp)>=0?temp:pu}`
    },
    filterAnswer(answer, type) {
      //if(answer < 1) return "NA"
      const options = 'ABCDEFGHIJ'.split('')
      if(type== 3){
        return options[parseInt(answer)]
      } else if(type== 4){
        if(!answer) return ""
        return answer.split(',').map( v => options[parseInt(v)]).join(', ')
      } 
      return answer
    }
  }
}
</script>
<style >
.min80vh{
  min-height: 80vh;
}
.test-logo{
	padding: 4px;
	margin: 5px 20px;
}
#mobile-toolbar>.v-toolbar__content{
  justify-content:space-between
}
.timer-icon i{
  margin-bottom: -4px;
}
.table-bordered{
  border-collapse: collapse;
}
.table-bordered thead{
  padding: 5px 0
}
.table-bordered  td{
  border:1px solid #eee;
  padding: 5px;
}
</style>